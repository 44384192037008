import { I18n } from "i18n-js";
import * as Sentry from "@sentry/react";
import localeFr from "../locales/fr.json";
import localeNl from "../locales/nl.json";
import localeEn from "../locales/en.json";

const country = document.documentElement.dataset?.country || "fr";

const IMPERIAL_COUNTRIES = ["UK"];

const i18n = new I18n({
  fr: localeFr,
  fr_CH: localeFr,
  fr_BE: localeFr,
  fr_LU: localeFr,
  nl: localeNl,
  en: localeEn,
});

i18n.locale = document.documentElement.lang.replace("-", "_");

const currency = () => {
  switch (country) {
    case "ch":
      return "CHF";
    case "uk":
      return "£";
    default:
      return "€";
  }
};

const currencyLong = () => {
  switch (country) {
    case "ch":
      return "CHF";
    case "uk":
      return "GBP";
    default:
      return "EUR";
  }
};

const insurance = () => {
  switch (country) {
    case "uk":
      return null;
    case "ch":
      return "Yoojo";
    default:
      return "AXA";
  }
};

const toKm = (distance) => {
  const isImperial = IMPERIAL_COUNTRIES.includes(country.toUpperCase());
  if (isImperial) {
    return Math.round(distance / 1.60934);
  }
  return distance;
};

const toDuration = (hours, isDuration, noDecimal) => {
  const newHours = !isDuration ? hours - (hours >= 24 ? 24 : 0) : hours;
  if (parseFloat(newHours) % 1 === 0) {
    if (i18n.locale === "nl") {
      const decimal = noDecimal ? "urr" : " uur";
      return `${Math.floor(newHours)}${decimal}`;
    }
    const decimal = noDecimal ? "h" : "h00";
    return `${parseFloat(newHours)}${decimal}`;
  }
  if (i18n.locale === "nl") {
    return `${Math.floor(newHours)} uur 30`;
  }
  return `${Math.floor(newHours)}h30`;
};

const toTime = (time) => {
  let res;
  if (parseFloat(time) % 1 === 0) {
    res = `${parseFloat(time)}:00`;
  } else {
    res = `${Math.floor(time)}:30`;
  }
  return res.padStart(5, "0");
};

const toSmartCurrency = (amount, config = {}) => {
  const parsedAmount = parseFloat(amount);
  const hasDecimal = parsedAmount % 1 !== 0;

  const fixedAmount =
    hasDecimal || !config?.noDecimal
      ? parsedAmount.toFixed(2)
      : parsedAmount.toFixed(0);

  return `${fixedAmount.replace(".", ",")}${String.fromCharCode(160)}${
    config?.currency || i18n.currency
  }${config.pricePerHour ? "/h" : ""}`;
};

const onMissingKey = (key) => {
  Sentry.captureException(`Missing translation for key: ${key}`);
  console.error("Missing translation for key:", key);
  return key;
};

const toDistance = (distance = 0, config) => {
  let newDistance = distance;
  let unit;
  const isImperial = IMPERIAL_COUNTRIES.includes(country.toUpperCase());
  if (config?.noFormat) {
    unit = isImperial
      ? i18n.t("common.miles", { count: distance })
      : i18n.t("common.kilometers_short", { count: distance });
    return `${
      isImperial ? distance : distance.toString().replace(".", ",")
    } ${unit}`;
  }
  if (distance >= 1000) {
    newDistance = isImperial ? distance / 5280 : distance / 1000;
    unit = isImperial
      ? i18n.t("common.miles", { count: newDistance })
      : i18n.t("common.kilometers_short", { count: newDistance });
  } else {
    unit = isImperial
      ? i18n.t("common.feet")
      : i18n.t("common.meters", { count: newDistance });
  }

  return `${newDistance.toFixed(
    isImperial && newDistance >= 1000 ? 2 : 0
  )} ${unit}`;
};

i18n.defaultLocale = "fr";
i18n.enableFallback = true;
i18n.missingTranslation.get = onMissingKey;
i18n.currency = currency();
i18n.toDistance = toDistance;
i18n.currencyLong = currencyLong();
i18n.country = country;
i18n.toSmartCurrency = toSmartCurrency;
i18n.toDuration = toDuration;
i18n.toTime = toTime;
i18n.toKm = toKm;
i18n.insurance = insurance();

export default i18n;
