import React, { useLayoutEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  shadows,
  spacings,
} from "../../assets/themes";
import { useOnClickOutside } from "../../modules/hooks";
import Block from "../Block";
import { POPOVER } from "../Styles/variants";
import { Body16 } from "../Text";
import PortalContent from "./PortalContent";

const MOUSE_OUTSIDE_HIDE_DELAY = 200;

const setProps = ({ trigger, show: handleShow, hide: handleHide }) => {
  const p = {};
  if (trigger === POPOVER.TRIGGER_TYPE.CLICK) {
    p.onClick = handleShow;
  }
  if (trigger === POPOVER.TRIGGER_TYPE.FOCUS) {
    p.onFocus = handleShow;
    p.onBlur = handleHide;
  }
  if (trigger === POPOVER.TRIGGER_TYPE.HOVER) {
    // onMouseEnter -> on parent event
    p.onMouseEnter = handleShow;
    p.onFocus = handleShow;
    p.onBlur = handleHide;
  }
  return p;
};

const PopoverContainer = ({ children, trigger, show, hide }) =>
  React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...setProps({ trigger, show, hide }),
    })
  );

const Enhancer = ({
  position = POPOVER.POSITIONS.BOTTOM,
  trigger = POPOVER.TRIGGER_TYPE.HOVER,
  content,
  children,
  mountNode,
  onClose,
  onOpen,
  isOpen = false,
  strategy = POPOVER.STRATEGY.ABSOLUTE,
  onMouseLeaveDelay = MOUSE_OUTSIDE_HIDE_DELAY,
  ...rest
}) => {
  let to;
  const [referenceElement, setReferenceElement] = useState(null);
  const [preventShowAfterOutside, setPreventShowAfterOutside] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [isVisible, setIsVisible] = useState(true); // True by default for popper get default rect, set to False in the layout effect
  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: position,
    strategy,
    modifiers: [
      {
        name: "flip",
        options: {
          padding: 16,
          flipVariations: true,
        },
      },
      {
        name: "preventOverflow",
        options: {
          altBoundary: false,
          padding: 16,
        },
      },
      {
        name: "offset",
        options: {
          offset: [8, 8],
        },
      },
    ],
  });

  const handleShow = (e) => {
    e.stopPropagation();
    clearTimeout(to);
    if (!preventShowAfterOutside) {
      if (onOpen) onOpen(e);
      setIsVisible(true);
    }
  };

  const handleHide = ({ noDelay = false }) => {
    if (noDelay) {
      setIsVisible(false);
      if (onClose) onClose();
    } else {
      to = setTimeout(() => {
        setIsVisible(false);
        if (onClose) onClose();
      }, onMouseLeaveDelay);
    }
  };

  const handleMouseLeave = () => {
    if (trigger === POPOVER.TRIGGER_TYPE.HOVER) {
      handleHide();
    }
  };

  const handleMouseEnter = () => {
    clearTimeout(to);
  };

  const handleClickOutside = (e) => {
    if (trigger === POPOVER.TRIGGER_TYPE.CLICK && isVisible) {
      setPreventShowAfterOutside(true);
      handleHide(e);
    }
  };

  useLayoutEffect(() => {
    setIsVisible(isOpen);
  }, []);

  useOnClickOutside({ current: popperElement }, handleClickOutside);

  return (
    <Block
      {...rest}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <div ref={setReferenceElement}>
        <PopoverContainer trigger={trigger} show={handleShow} hide={handleHide}>
          {children}
        </PopoverContainer>
      </div>

      <PortalContent
        position={state?.placement || position}
        isVisible={isVisible}
        mountNode={mountNode}
        styles={styles}
        ref={setPopperElement}
        onAnimationComplete={() => {
          setPreventShowAfterOutside(false);
        }}
      >
        {content && content({ close: () => handleHide({ noDelay: true }) })}
      </PortalContent>
    </Block>
  );
};

const StyledTooltip = styled.div`
  border-radius: ${radius.l};
  padding: ${spacings.sm} ${spacings.m};
  background-color: ${colors.backgroundDark};
  max-width: ${POPOVER.TOOLTIP_MAX_WIDTH}px;
  z-index: 999;
`;

const Tooltip = ({ children }) => (
  <StyledTooltip>
    <Body16 color={colors.onColor}>{children}</Body16>
  </StyledTooltip>
);

const Menu = styled.div`
  border-radius: ${radius.l};
  border: solid ${borderWidth.s} ${colors.border};
  background-color: ${colors.background};
  box-shadow: ${shadows.m};
  padding-top: ${spacings.xs};
  padding-bottom: ${spacings.xs};
  overflow: hidden;
`;

export default { Enhancer, Elem: { Tooltip, Menu } };
