// eslint-disable-next-line import/no-unresolved
import { useEffect } from "react";
import { MemoryRouter, Route, useHistory } from "react-router-dom";
import { Modal } from "../components";
import { useBreakpoints, useListenToTargetClick } from "../modules/hooks";
import Show from "./categoriesNavigationModal/Show";

const RouteContent = ({
  selector,
  isOpen = false,
  onClose,
  category_id,
  children,
  ...rest
}) => {
  const breakpoints = useBreakpoints();
  const history = useHistory();

  const handleGoToCategory = (id) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("search", id);
    history.push(`?${searchParams.toString()}`);
  };

  const handleSelectorClick = (e) => {
    const searchParams = new URLSearchParams(history.location.search);
    const id = Number(e?.currentTarget?.dataset?.categoryId);
    searchParams.append("search", id || category_id || "");
    history.push({
      pathname: "",
      search: searchParams.toString(),
    });
  };

  const handleClose = () => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.delete("search");
    history.push({
      pathname: "",
      search: searchParams.toString(),
    });
    if (onClose) onClose();
  };

  useEffect(() => {
    if (!selector) {
      if (isOpen) {
        handleSelectorClick();
      } else {
        handleClose();
      }
    }
  }, [isOpen]);

  useListenToTargetClick(selector, handleSelectorClick);

  return (
    <Route
      path="/"
      render={(route) => {
        if (!route.location.search.includes("search=")) {
          return null;
        }
        return (
          <Modal.Medium
            isOpen
            onClose={handleClose}
            fullScreenOnMobile
            css={`
              > div:last-of-type {
                align-items: flex-start;
                > div {
                  margin-top: ${breakpoints.get({ xs: 0, sm: "4vh" })};
                  margin-bottom: ${breakpoints.get({ xs: 0, sm: "4vh" })};
                  max-height: ${breakpoints.get({ xs: "inherit", sm: "92vh" })};
                  display: block;
                  overflow-y: ${breakpoints.get({ xs: "initial", sm: "auto" })};
                }
              }
            `}
          >
            <Show goTo={handleGoToCategory} onClose={handleClose} {...rest} />
          </Modal.Medium>
        );
      }}
    />
  );
};

const CategoriesNavigationModal = (props) => (
  <MemoryRouter>
    <RouteContent {...props} />
  </MemoryRouter>
);

export default CategoriesNavigationModal;
