import IconPlus from "../assets/images/svg-icons/direct/icon_plus.svg";
import IconBasic from "../assets/images/svg-icons/direct/icon_basic.svg";
import polyglot from "./polyglot";

export const ID_OFFSET = 103286;

export const MAX_FIRST_NAME_LENGTH = 250;
export const MAX_LAST_NAME_LENGTH = 250;

export const PASSWORD_MINIMAL_LENGTH = 8;

export const WINDOW_WIDTH = window.innerWidth;

export const BREAKPOINTS = { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 };

export const YPJ = {
  YPJ_END_POINT: "http://localhost:3000",
};

export const SUPPORT_LINES = {
  YOOJO_DIRECT: "yoojo_direct",
  BOOKING: "booking",
  CANCELLATION: "cancellation",
};

export const JOB_COMMENT_MAX_LENGTH = 1000;

export const CESU_SCAN_RANGE = 3;

export const VISA_MASTERCARD = "visa_mastercard";
export const PAYPAL = "paypal";
export const MAESTRO = "maestro";
export const AMEX = "amex";
export const IBAN = "iban";

export const DIRECT_PLANS = {
  direct_basic: {
    title: "Basic",
    price: 9.9,
    artwork: IconBasic,
    advantages: [
      { title: "Paie de votre prestataire automatisée" },
      { title: "Fiche de paie automatisée" },
      { title: "Déclaratif automatisé (optionnel)" },
      { title: "Contrat de travail inclus (optionnel)" },
      { title: "Assistance téléphonique gratuite" },
      {
        title: "5% de remise sur les services ponctuels",
        subtitle: "Réparation, aménagement, garde, rangement, et d’autres ...",
      },
    ],
  },
  direct_plus: {
    title: "Plus",
    price: 14.9,
    artwork: IconPlus,
    advantages: [
      { title: "Paie de votre prestataire automatisée" },
      { title: "Fiche de paie automatisée" },
      { title: "Déclaratif automatisé (optionnel)" },
      { title: "Contrat de travail inclus (optionnel)" },
      { title: "Assistance téléphonique gratuite" },
      {
        title: "10% de remise sur les services ponctuels",
        subtitle: "Réparation, aménagement, garde, rangement, et d’autres ...",
      },
      {
        title: "Assurance incluse",
        subtitle: "Nous prenons en charge la casse et les accidents",
      },
      {
        title: "Remplacement de votre prestataire en cas d’imprévu",
        subtitle:
          "Nous trouvons un autre prestataire en cas d’imprévu ou de départ",
      },
    ],
  },
};

export const EXEMPTIONS = [
  { label: "Vous ou votre conjoint avez +70 ans", value: 0 },
  {
    label: "Vous bénéficiez de l’Allocation personnalisée d’autonomie (APA)",
    value: 1,
  },
  {
    label:
      "Vous bénéficiez de la Prestation de compensation du handicap (PCH ou ACTP)",
    value: 2,
  },
  { label: "Vous êtes titulaire d’une carte d’invalidité à 80 %", value: 3 },
  { label: "Vous bénéficiez de la Majoration pour tierce personne", value: 4 },
  {
    label:
      "Vous bénéficiez du complément de l’allocation d’éducation enfant handicapé (AEEH)",
    value: 5,
  },
];

export const MANGOPAY = {
  MANGOPAY_END_POINT: "/mangopay_cards",
};
export const USER_SEX = {
  1: polyglot.t("common.male"),
  2: polyglot.t("common.female"),
};
export const IS_DEV =
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "test";

export const IS_PROD =
  process.env.NODE_ENV === "production" || process.env.NODE_ENV === "prod";

export const IS_STAGING =
  window.location.hostname.includes("yooplajob") ||
  window.location.hostname.includes("youplajob");

export const MANGOPAY_CLIENT_ID =
  IS_PROD && !IS_STAGING ? "hwvi6nr8k4vf" : "xvv7xyogrj1s";

export const GOOGLE_PAY_MERCHAND_ID =
  IS_PROD && !IS_STAGING ? "BCR2DN4TZ3C7HSZZ" : "TEST";

export const DASHBOARD_SMALL_CONTAINER = 550;
export const BRAND_NAME = "Yoojo";
export const CHAT_THUMBNAILS_KIND = { jobs: "jobs", tickets: "tickets" };
export const MAX_JOB_PHOTOS = 3;
export const GET_RATE_URL = (id) => `offers/${id}/rates/new`;

export const DEFAULT_CATEGORY_NB_HOURS = 2;

export const INSTANT_GO_NEXT_DELAY = 100;

export const PREFERED_COUNTRIES = ["FR", "CH", "BE", "LU", "NL", "GB"];

export const JOB_MIN_HOUR = 7;
export const JOB_MAX_HOUR = 21;

export const NEW_JOBBER_CREDIT = 5;

export const JOBBER_SEARCH_DISTANCE_MIN = 1;
export const JOBBER_SEARCH_DISTANCE_MAX = 50;
export const JOBBER_SEARCH_PRICE_MIN = 9;
export const JOBBER_SEARCH_PRICE_MAX = 50;

export const JOBBER_SEARCH_ROLES = [
  "handyman",
  "handyman2",
  "painter",
  "plumber",
  "electrician",
  "locksmith",
  "gardener",
  "mover",
  "maid",
  "domestic_help",
  "cleaner",
  "baby_sitter",
  "nanny",
  "maternity_attendant",
  "pet_sitter",
  "dog_sitter",
  "cat_sitter",
  "computer_scientist",
  "concierge",
  "life_auxiliary",
  "professor",
];

export const NON_ELIGIBLE_DIPLOMA_SKILLS = ["baby_sitting"];
export const ELIGIBLE_SMOKER_AND_DRIVER_SKILLS = ["baby_sitting"];

export const BABY_SITTING_HAVE_DIPLOMA_SUBTITLE = [
  "bafa",
  "cap",
  "assp",
  "deap",
  "deeje",
  "meef",
  "dep",
  "cs",
];
