import React, { useState } from "react";
import { ButtonBase } from "../Styles/Base";
import ImageGalleryModal from "./ImageGalleryModal";

const ImageGallery = ({ children, className }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(null);

  const handleImageClick = (index) => {
    setInitialIndex(index);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setInitialIndex(null);
    setModalIsOpen(false);
  };

  return (
    <>
      {React.Children.map(children, (child, index) => (
        <ButtonBase
          type="button"
          onClick={() => handleImageClick(index)}
          css={`
            cursor: zoom-in;
          `}
          className={className}
        >
          {React.cloneElement(child)}
        </ButtonBase>
      ))}
      {modalIsOpen && (
        <ImageGalleryModal
          isOpen={modalIsOpen}
          initialIndex={initialIndex}
          onClose={handleCloseModal}
        >
          {children}
        </ImageGalleryModal>
      )}
    </>
  );
};

export default ImageGallery;
